import React from 'react';
import { NavPages } from '../components/NavPages';
import { Footer } from '../components/Footer';

const Investment = () => {
  return (
    <>
      <NavPages />
      <div className='page'>
        <div className='container'>
          <div className='flex jcsb aic'>
            <h2 className='page-title w-30'>Інвесторам</h2>
            <p className='title c-dark w-50 fw-400 fz-14 lh-125'>
              Запрошуємо вас приєднатися до нашої платформи та відкрити нові
              можливості для інвестування в перспективні проекти та стартапи.
            </p>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='s-100'></div>
        <div className='row'>
          <div className='col-lg-6'>
            <h4 className='c-dark fw-600 upp'>
              Чому варто інвестувати з нами?
            </h4>
            <div className='s-24'></div>
            <div className='s-1 bg-lite'></div>
            <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
              Доступ до інноваційних ідей: Отримайте ранній доступ до проектів з
              високим потенціалом зростання.
            </p>
            <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
              Прозорість і безпека: Ми забезпечуємо повну прозорість угод та
              захист ваших інвестицій.
            </p>
            <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
              Зручність: Інвестуйте онлайн у зручний для вас час та місце.
            </p>
            <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
              Підтримка експертів: Наша команда надає професійну підтримку на
              всіх етапах інвестування.
            </p>
          </div>
          <div className='col-lg-6'>
            <h4 className='c-dark fw-600 upp'>Як розпочати інвестувати?</h4>
            <div className='s-24'></div>
            <div className='s-1 bg-lite'></div>
            <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
              Зареєструйтеся на нашій платформі.
            </p>
            <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
              Ознайомтеся з доступними проектами та їх деталями.
            </p>
            <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
              Виберіть проекти, які відповідають вашим інвестиційним цілям.
            </p>
            <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
              Інвестуйте та слідкуйте за розвитком своїх вкладень.
            </p>
            <p className='c-dark fw-500 flex jcsb p-y-12 m-y-0 bw-1 bb'>
              Готові зробити перший крок?
            </p>
          </div>
        </div>
      </div>
      <div className='s-40'></div>
      <Footer />
    </>
  );
};

export { Investment };
