import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import title from '../../assets/title2.svg';
import projects from '../../data/projects';
import { Link } from 'react-router-dom';
import { createSlug } from '../../components/utils';

const Projects = () => {
  const Gallery = () => {
    var settings = {
      autoplay: true,
      dots: false,
      arrows: true,
      infinite: true,
      speed: 2000,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      pauseOnHover: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <Slider {...settings}>
        {projects.map((el) => {
          const slug = createSlug(el.title);
          return (
            <div className='px-3' key={el.id}>
              <div className='p-x-48 p-y-40 gallery r-16 w-100'>
                <div className='flex aic w-100 jcfs'>
                  <img
                    src={el.pic}
                    alt={el.title}
                    width={48}
                    height={48}
                    className='m-b-8 m-r-16'
                  />
                  <div>
                    <h4 className='c-white title-sub p-t-8 lh-1 '>
                      {el.title}
                    </h4>
                    <p className='c-white lh-1'>{el.name}</p>
                  </div>
                </div>
                <p className='c-white py-1 title-sub fw-300 hv-15 lh-135'>
                  {el.desc}
                </p>
                <div className='flex jcsb'>
                  <div className='flex aife'>
                    <p className='c-white p-b-2'>Бонус:</p>
                    <span className='w-10'></span>
                    <p className='c-white title-sub'>{el.bonus}</p>
                  </div>
                  <span className='c-white border br-4 bc-white r-4 px-2 hp-26'>
                    {el.status}
                  </span>
                </div>
                <div className='avatars flex'>
                  {el.avatars.map((avatar, j) => (
                    <img
                      key={j}
                      src={avatar}
                      alt='avatar'
                      width={32}
                      height={32}
                    />
                  ))}
                </div>
                <div className='s-20'></div>
                <div className='flex aic'>
                  <Link to={'/'} className='button first mini'>
                    Приєднатися
                  </Link>
                  <Link
                    to={el.pr ? `/projects/${slug}` : '/'}
                    className='button mini'
                  >
                    Подробиці
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    );
  };

  return (
    <section id='pr'>
      <div className='flex aic jcc w-100 hv-100'>
        <div className='container'>
          <div className='s-40'></div>
          <div className='tc'>
            <img src={title} alt='Вітрина організацій' />
          </div>
          <div className='s-40'></div>
          <Gallery />
          <div className='s-40'></div>
          <div className='tc'>
            <a className='button first px-5' href='/projects'>
              Всі Організації
            </a>
          </div>

          <div className='s-40'></div>
        </div>
      </div>
    </section>
  );
};

export { Projects };
