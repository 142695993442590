import React, { useState } from 'react';
import title from '../../assets/title1.svg';
import t1 from '../../assets/t1.svg';
import t2 from '../../assets/t2.svg';
import t3 from '../../assets/t3.svg';

const HowWork = () => {
  const [first, setFirst] = useState(true);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);

  const handleFirst = () => {
    setFirst(true);
    setSecond(false);
    setThird(false);
  };
  const handleSecond = () => {
    setFirst(false);
    setSecond(true);
    setThird(false);
  };
  const handleThird = () => {
    setFirst(false);
    setSecond(false);
    setThird(true);
  };

  return (
    <section id='how'>
      <div className='flex aic jcc w-100 hv-100'>
        <div className='content'>
          <div className='container'>
            <div className='tc'>
              <img src={title} alt='Як це працює' />
            </div>
            <div className='s-40'></div>
            <div className='row'>
              <div className='col-lg-4'>
                <button
                  onClick={handleFirst}
                  className={`title card-res tab tab-1 flex aic b-none ${
                    first && 'active'
                  }`}
                >
                  <div className='tl flex aic'>
                    <img src={t2} alt='Реєстрація' width={40} height={40} />
                    <span className='title c-white px-3'>Реєстрація</span>
                  </div>
                </button>
                <div className='s-20'></div>
                <button
                  onClick={handleSecond}
                  className={`title card-res tab tab-2 flex aic b-none ${
                    second && 'active'
                  }`}
                >
                  <div className='tl flex aic'>
                    <img src={t3} alt='Реєстрація' width={40} height={40} />
                    <span className='title c-white px-3'>Аналіз цінностей</span>
                  </div>
                </button>
                <div className='s-20'></div>
                <button
                  onClick={handleThird}
                  className={`title card-res tab tab-3 flex aic b-none ${
                    third && 'active'
                  }`}
                >
                  <div className='tl flex aic'>
                    <img src={t1} alt='Реєстрація' width={40} height={40} />
                    <span className='title c-white px-3'>Декларування</span>
                  </div>
                </button>
              </div>
              <div className='col-lg-8 pl-x-3'>
                {first && (
                  <div className='card-res how how-1'>
                    <h3 className='title-intro-sub c-white'>Реєстрація</h3>
                    <p className='title c-white py-2'>
                      Зареєструй вітруальну компанію та випусти віртуальні
                      бонуси
                    </p>
                    <p className='title c-white py-2'>
                      Роздайте віртуальні бонуси участникам команди та спонсорам
                    </p>
                    <p className='title c-white py-2'>
                      Опублікуйте інформацію як та коли бонуси стануть раальною
                      цінністтю
                    </p>
                    <div className='s-20'></div>
                    <a href='#f' className='button'>
                      Розпочати
                    </a>
                  </div>
                )}
                {second && (
                  <div className='card-res how how-2'>
                    <h3 className='title-intro-sub c-white'>
                      Аналіз цінностей
                    </h3>
                    <p className='title c-white py-2'>
                      Зареєструй вітруальну компанію та випусти віртуальні
                      бонуси
                    </p>
                    <p className='title c-white py-2'>
                      Роздайте віртуальні бонуси участникам команди та спонсорам
                    </p>
                    <p className='title c-white py-2'>
                      Опублікуйте інформацію як та коли бонуси стануть раальною
                      цінністтю
                    </p>
                    <div className='s-20'></div>
                    <a href='#f' className='button'>
                      Розпочати
                    </a>
                  </div>
                )}
                {third && (
                  <div className='card-res how how-3'>
                    <h3 className='title-intro-sub c-white'>Декларування</h3>
                    <p className='title c-white py-2'>
                      Зареєструй вітруальну компанію та випусти віртуальні
                      бонуси
                    </p>
                    <p className='title c-white py-2'>
                      Роздайте віртуальні бонуси участникам команди та спонсорам
                    </p>
                    <p className='title c-white py-2'>
                      Опублікуйте інформацію як та коли бонуси стануть раальною
                      цінністтю
                    </p>
                    <div className='s-20'></div>
                    <a href='#f' className='button'>
                      Розпочати
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { HowWork };
