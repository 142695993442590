import React from 'react';
import { NavPages } from '../components/NavPages';
import { Footer } from '../components/Footer';
import { createSlug } from '../components/utils';
import { useParams } from 'react-router-dom';
import posts from '../data/posts';

function BlogPost() {
  const { slug } = useParams();

  const post = posts.find((p) => createSlug(p.title) === slug);

  if (!post) {
    return '';
  }

  return (
    <div>
      <NavPages />
      <div className='page'>
        <div className='container'>
          <div className='flex jcsb aic'>
            <h2 className='page-title w-30'>Корисні Статті</h2>
            <p className='title c-dark w-57 fw-400 fz-14 lh-135'>
              Дізнавайтеся про нові можливості платформи, успішні історії
              користувачів та отримуйте цінні поради для розвитку ваших проектів
            </p>
          </div>
        </div>
      </div>
      <div className='s-60'></div>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-5 p-r-60'>
            <img
              src={post.pic}
              alt={post.title}
              width={420}
              height={420}
              className='r-8 obfc w-100'
            />
          </div>
          <div className='col-lg-7'>
            <div className='s-8'></div>
            <h4 className='c-dark page-title p-t-8 fw-600'>{post.title}</h4>
            <div className='s-16'></div>
            <div className='s-2 bg-lite'></div>
            <div className='s-16'></div>
            <p className='c-dark lh-1'>{post.date}</p>
            <div className='s-2 bg-lite'></div>
            <div className='s-16'></div>
            <p className='c-dark py-1 fw-400'>{post.text1}</p>
            <p className='c-dark py-1 fw-400'>{post.text2}</p>
            <p className='c-dark py-1 fw-400'>{post.text3}</p>
            <p className='c-dark py-1 fw-400'>{post.text4}</p>
            <div className='s-20'></div>
          </div>
        </div>
      </div>
      <div className='s-40'></div>
      <Footer />
    </div>
  );
}
export { BlogPost };
