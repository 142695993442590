import blog1 from '../assets/blog_1.jpg';
import blog2 from '../assets/blog_2.jpg';
import blog3 from '../assets/blog_3.jpg';
import blog4 from '../assets/blog_4.jpg';
import blog5 from '../assets/blog_5.jpg';
import blog6 from '../assets/blog_6.jpg';

const posts = [
  {
    id: 1,
    title: 'Нові можливості нашої платформи',
    date: '5 березня 2024',
    pic: blog1,
    summary:
      'Ми додали нові функції для покращення вашого досвіду користування.',
    text1:
      'Ми раді повідомити про запуск нових функцій на нашій платформі. Ці оновлення спрямовані на підвищення ефективності та зручності використання.',
    text2:
      'Однією з головних новинок є покращений інтерфейс користувача, який став більш інтуїтивно зрозумілим. Це дозволить вам швидше знаходити необхідні інструменти та функції.',
    text3:
      'Ми також додали нові можливості для співпраці в реальному часі. Тепер ви можете працювати з командою ще ефективніше, незалежно від вашого місцезнаходження.',
    text4:
      'Дякуємо за вашу підтримку та зворотний зв’язок. Ми продовжимо вдосконалювати нашу платформу, щоб задовольнити всі ваші потреби.',
  },
  {
    id: 2,
    title: 'Як успішно залучити інвесторів',
    date: '12 квітня 2024',
    pic: blog2,
    summary:
      'Поради щодо ефективного представлення свого проекту потенційним інвесторам.',
    text1:
      'Залучення інвесторів є ключовим етапом розвитку будь-якого проекту. Важливо правильно підготуватися до презентації свого бізнесу.',
    text2:
      'Перш за все, чітко визначте унікальність вашого продукту або послуги. Поясніть, яку проблему ви вирішуєте та яку цінність приносите ринку.',
    text3:
      'Створіть детальний бізнес-план з прогнозами фінансового зростання. Інвестори хочуть бачити потенціал повернення своїх вкладень.',
    text4:
      'Не забувайте про комунікаційні навички. Впевнена та переконлива презентація може значно підвищити ваші шанси на успіх.',
  },
  {
    id: 3,
    title: 'Історії успіху: Наші користувачі',
    date: '20 травня 2024',
    pic: blog3,
    summary: 'Реальні приклади проектів, які досягли успіху з нашою допомогою.',
    text1:
      'Ми пишаємося успіхами наших користувачів, які досягли значних результатів завдяки нашій платформі.',
    text2:
      'Один з таких прикладів — компанія "EcoPackUA", яка змогла залучити інвесторів та розширити свій бізнес.',
    text3:
      'Інший успішний проект — "SmartCityHub", що отримав грант на розвиток свого рішення для міської інфраструктури.',
    text4:
      'Ці історії надихають нас продовжувати працювати та допомагати іншим досягати своїх цілей.',
  },
  {
    id: 4,
    title: 'Оновлення політики безпеки',
    date: '15 червня 2024',
    pic: blog4,
    summary:
      'Дізнайтеся про зміни в політиці конфіденційності та безпеки даних.',
    text1:
      'Ми оновили нашу політику безпеки, щоб ще краще захищати ваші дані та конфіденційну інформацію.',
    text2:
      'Нові положення включають покращені механізми шифрування та захисту від несанкціонованого доступу.',
    text3:
      'Ми також запровадили додаткові інструменти для контролю над вашою особистою інформацією.',
    text4:
      'Будь ласка, ознайомтеся з новою політикою на нашому сайті та звертайтеся до нас з будь-яких питань.',
  },
  {
    id: 5,
    title: 'Вебінар: Створення віртуального стартапу',
    date: '30 липня 2024',
    pic: blog5,
    summary:
      'Приєднуйтесь до нашого вебінару та навчіться створювати власні проекти.',
    text1:
      'Запрошуємо вас на наш безкоштовний вебінар, де ми розповімо про те, як створити віртуальний стартап на нашій платформі.',
    text2:
      'Під час вебінару ви дізнаєтесь про основні кроки та інструменти, необхідні для запуску успішного проекту.',
    text3:
      'Наші експерти поділяться практичними порадами та відповіді на ваші запитання.',
    text4:
      'Реєстрація вже відкрита. Не пропустіть цю унікальну можливість отримати цінні знання.',
  },
  {
    id: 6,
    title: 'Поради для ефективної командної роботи',
    date: '10 серпня 2024',
    pic: blog6,
    summary: 'Як покращити співпрацю в команді та досягти спільних цілей.',
    text1:
      'Ефективна командна робота є запорукою успіху будь-якого проекту. Важливо налагодити комунікацію між усіма учасниками.',
    text2:
      'Встановіть чіткі ролі та обов’язки для кожного члена команди. Це допоможе уникнути плутанини та дублювання зусиль.',
    text3:
      'Регулярно проводьте зустрічі та обговорення прогресу. Відкрите спілкування сприяє вирішенню проблем на ранніх етапах.',
    text4:
      'Стимулюйте мотивацію команди через визнання досягнень та спільні цілі. Це підвищить залученість та продуктивність.',
  },
];
export default posts;
