import React, { useState, useEffect, useCallback } from 'react';
import { PopUp } from './parts/PopUp';
import { Intro } from './parts/Intro';
import { HowWork } from './parts/HowWork';
import { Projects } from './parts/Projects';
import { Cta } from './parts/Cta';

const sections = [<Intro />, <HowWork />, <Projects />, <Cta />];

const Home = () => {
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [scrolling, setScrolling] = useState(false);

  const handleScroll = useCallback(
    (event) => {
      if (scrolling) return;

      setScrolling(true);

      if (event.deltaY > 0 && currentSectionIndex < sections.length - 1) {
        setCurrentSectionIndex((prev) => prev + 1);
      } else if (event.deltaY < 0 && currentSectionIndex > 0) {
        setCurrentSectionIndex((prev) => prev - 1);
      }

      setTimeout(() => setScrolling(false), 700);
    },
    [currentSectionIndex, scrolling]
  );

  useEffect(() => {
    window.addEventListener('wheel', handleScroll);
    return () => window.removeEventListener('wheel', handleScroll);
  }, [handleScroll]);

  return (
    <>
      <PopUp />
      <div style={{ height: '100vh', overflow: 'hidden' }}>
        <div
          style={{
            transform: `translateY(-${currentSectionIndex * 100}vh)`,
            transition: 'transform 0.5s',
          }}
        >
          {sections.map((SectionComponent, index) => (
            <section
              key={index}
              style={{
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {SectionComponent}
            </section>
          ))}
        </div>
      </div>
    </>
  );
};

export { Home };
