import React, { useState, useEffect } from 'react';
import logo from '../assets/logo-full.svg';

const NavPages = () => {
  const [opened, setOpened] = useState(true);

  useEffect(() => {
    if (window.innerWidth < 769 && !opened) {
      document.body.classList.add('menu-active');
    } else {
      document.body.classList.remove('menu-active');
    }
  }, [opened]);

  const toggleMenu = () => {
    setOpened(!opened);
  };

  useEffect(() => {
    const handleHashChange = () => {
      if (window.location.hash) {
        window.history.replaceState(null, null, ' ');
      }
    };

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return (
    <header className='nav-pages'>
      <div className='container'>
        <nav>
          <h1 id='logo'>
            <a href='/' aria-label='Logotype'>
              <img src={logo} width={137} height={44} alt='eBonus' />
            </a>
          </h1>
          <div
            className={opened ? 'burger' : 'burger open'}
            onClick={toggleMenu}
          >
            <span className='tt'></span>
            <span className='mm'></span>
            <span className='bb'></span>
          </div>
          <div className={opened ? 'menu active' : 'menu'}>
            <a href='/' className='mx-2 px-1' onClick={toggleMenu}>
              Головна
            </a>
            <a href='/how-it-works' className='mx-2 px-1' onClick={toggleMenu}>
              Приклади
            </a>
            <a href='/reliability' className='mx-2 px-1' onClick={toggleMenu}>
              Надійність
            </a>
            <a href='/projects' className='mx-2 px-1' onClick={toggleMenu}>
              Вітрина
            </a>
            <a href='/for-investors' className='mx-2 px-1' onClick={toggleMenu}>
              Інвесторам
            </a>
            <a href='/blog' className='mx-2 px-1'>
              Блог
            </a>
            <a href='/' className='button up'>
              Розпочати безкоштовно
            </a>
          </div>
        </nav>
      </div>
    </header>
  );
};

export { NavPages };
