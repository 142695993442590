import React from 'react';

const Cta = () => {
  return (
    <section id='cta'>
      <div className='flex aic jcc w-100 hv-100'>
        <div className='container tc'>
          <h2 className='title-intro c-white upp fw-700'>
            наскільки це надійно
          </h2>
          <div className='s-10'></div>
          <h1 className='title-intro-sub c-white w-100 tc'>
            Захищаємо від конфліктів при розподілі прибутку
          </h1>
          <div className='s-40'></div>
          <a className='button first px-5' href='#form'>
            Розпочати
          </a>
          <p className='c-white copyright'>
            eBonus @ 2024 - Усі права захищено
          </p>
        </div>
      </div>
    </section>
  );
};

export { Cta };
