import React, { useState } from 'react';

const PopUp = () => {
  const [show, setShow] = useState(() => {
    const acceptedCookies = localStorage.getItem('acceptedCookies');
    return acceptedCookies !== 'true';
  });

  const acceptCookies = () => {
    localStorage.setItem('acceptedCookies', 'true');
    setShow(false);
  };

  const declineCookies = () => {
    localStorage.setItem('acceptedCookies', 'false');
    setShow(false);
  };

  return (
    show && (
      <div
        style={{
          position: 'fixed',
          left: 0,
          right: 0,
          bottom: 0,
          boxShadow: '0 1px 24px rgba(0,0,0,.15)',
          backgroundColor: 'white',
          zIndex: 9999,
        }}
      >
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-1'></div>
            <div className='col-lg-8'>
              <div className='s-30'></div>
              <h4 className='c-dark'>Cookie policy</h4>
              <p className='c-dark title-mini'>
                We use cookies to provide social media features and to analyse
                our traffic. We also share information about your use of our
                site with our social media, advertising, and analytics partners
                who may combine it with other information that you’ve provided
                to them or that they’ve collected from your use of their
                services. By closing, you’re agreeing to use cookies in line
                with our Cookie policy.
                <br />
                If you have any questions or need assistance, please don't
                hesitate to contact us. Stay connected with us for updates and
                stories about how your donation is making an impact!
              </p>
              <div className='s-10'></div>
            </div>
            <div className='col-lg-3 flex jcc aic'>
              <div className='tc'>
                <button className='button' onClick={acceptCookies}>
                  Accept and Close
                </button>
                <div className='s-10'></div>
                <button
                  className='button outline cookie'
                  onClick={declineCookies}
                >
                  Decline
                </button>
              </div>
            </div>
            <div className='m-30'></div>
          </div>
        </div>
      </div>
    )
  );
};

export { PopUp };
