import React from 'react';
import { NavPages } from '../components/NavPages';
import { Footer } from '../components/Footer';

const Cookie = () => {
  return (
    <>
      <NavPages />
      <div className='container'>
        <div className='s-40'></div>
        <div className='row'>
          <div className='col-lg-9 ma'>
            <div className='s-100'></div>
            <h2 className='c-theme fz-18 upp'>Cookie policy</h2>
            <div className='s-20'></div>
            <p className='c-dark'>
              We use “cookies” to collect information about you and your
              activity across our site. A cookie is a small piece of data that
              our website stores on your computer, and accesses each time you
              visit so we can understand how you use our site and serve you
              content based on preferences you have specified.
            </p>

            <p className='c-dark'>
              If you do not wish to accept cookies from us, you should instruct
              your browser to refuse cookies from our website, understanding
              that we may be unable to provide you with some of your desired
              services without them. This policy covers only the use of cookies
              between your computer and our website; it does not cover the use
              of cookies by any third-party services we use on our site.
            </p>

            <h4 className='c-dark py-3'>Third-Party Access To Information</h4>
            <p className='c-dark'>
              We may use third-party services for our website and marketing
              activity. These services may access our data solely for the
              purpose of performing specific tasks on our behalf. We do not
              share any personally identifying information with these services
              without your explicit consent. We do not give these services
              permission to disclose or use any of our data for any other
              purpose.
            </p>

            <p className='c-dark'>
              We will refuse government and law enforcement requests for data if
              we believe a request is too broad or unrelated to its stated
              purpose. However, we may cooperate if we
            </p>

            <p className='c-dark'>
              Believe the requested information is necessary and appropriate to
              comply with legal process;
            </p>
            <p className='c-dark'>To protect our own rights and property;</p>
            <p className='c-dark'>
              To protect the safety of the public and any person;
            </p>
            <p className='c-dark'>To prevent a crime;</p>
            <p className='c-dark'>
              To prevent what we reasonably believe to be illegal;
            </p>
            <p className='c-dark'>
              To prevent what we reasonably believe to be legally actionable, or
              unethical activity;
            </p>
            <p className='c-dark'>
              We do not otherwise share or supply personal information to third
              parties;
            </p>
            <p className='c-dark'>
              We do not sell or rent your personal information to marketers or
              third parties.
            </p>

            <h4 className='c-dark py-3'>Children’s Privacy</h4>
            <p className='c-dark'>
              This website does not knowingly target or collect personal
              information from children. As a parent/guardian, please contact us
              if you believe your child is participating in an activity
              involving personal information on our website, and you have no
              received a notification or request for consent. We do not use your
              supplied contact details for marketing or promotional purposes.
            </p>

            <h4 className='c-dark py-3'>Limits Of Our Policy</h4>
            <p className='c-dark'>
              This privacy policy only covers eBonus's own collecting and
              handling of data. We only work with partners, affiliates and
              third-party providers whose privacy policies align with ours,
              however we cannot accept responsibility or liability for their
              respective privacy practices.
            </p>

            <p className='c-dark'>
              Our website may link to external sites that are not operated by
              us. Please be aware that we have no control over the content and
              policies of those sites, and cannot accept responsibility or
              liability for their respective privacy practices.
            </p>

            <h4 className='c-dark py-3'>Changes To This Policy</h4>
            <p className='c-dark'>
              At our discretion, we may update this policy to reflect current
              acceptable practices. We will take reasonable steps to let users
              know about changes via our website. Your continued use of this
              site after any changes to this policy will be regarded as
              acceptance of our practices around data and personal information.
            </p>

            <h4 className='c-dark py-3'>Your Rights And Responsibilities</h4>
            <p className='c-dark'>
              As our user, you have the right to be informed about how your data
              is collected and used. You are entitled to know what data we
              collect about you, and how it is processed. You are entitled to
              correct and update any personal information about you, and to
              request this information be deleted.
            </p>

            <p className='c-dark'>
              You are entitled to restrict or object to our use of your data,
              while retaining the right to use your personal information for
              your own purposes. You have the right to opt out of data about you
              being used in decisions based solely on automated processing.
            </p>

            <p className='c-dark'>
              Feel free to contact us if you have any concerns or questions
              about how we handle your data and personal information.
            </p>
            <div className='s-60'></div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export { Cookie };
