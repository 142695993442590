import { React, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { Home } from './pages/Home';
import { Privacy } from './pages/Privacy';
import { Cookie } from './pages/Cookie';
import { Thanks } from './pages/Thanks';
import { NotFound } from './pages/NotFound';
import { HowItWorks } from './pages/HowItWorks';
import { Investment } from './pages/Investment';
import { Reliability } from './pages/Reliability';
import { Projects } from './pages/Projects';
import { Blog } from './pages/Blog';
import { BlogPost } from './pages/BlogPost';
import { SingleProject } from './pages/SingleProject';

import './styles/custom.sass';

function App() {
  useEffect(() => {
    TagManager.initialize({
      gtmId: 'GTM-PPS98NWB',
    });
  }, []);

  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path='how-it-works' element={<HowItWorks />} />
      <Route path='for-investors' element={<Investment />} />
      <Route path='reliability' element={<Reliability />} />
      <Route path='projects' element={<Projects />} />
      <Route path='projects/:slug' element={<SingleProject />} />
      <Route path='blog' element={<Blog />} />
      <Route path='blog/:slug' element={<BlogPost />} />
      <Route path='privacy' element={<Privacy />} />
      <Route path='cookie' element={<Cookie />} />
      <Route path='thank-you' element={<Thanks />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
}

export { App };
