import React from 'react';

const Footer = () => {
  return (
    <footer id='contact' className='bg-lite m-t-40'>
      <div className='container'>
        <div className='s-80'></div>
        <div className='row'>
          <div className='col-lg-7'>
            <h4 className='c-dark fw-600 upp'>
              Як ви можете використати нашу платформу?
            </h4>
            <div className='s-24'></div>
            <div className='s-1 bg-lite'></div>
            <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
              Реєструйтеся та створюйте віртуальні компанії чи проекти.
            </p>
            <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
              Випускайте віртуальні бонуси для учасників або інвесторів.
            </p>
            <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
              Розподіляйте бонуси серед команди, спонсорів та партнерів.
            </p>
            <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
              Декларуйте умови, за яких бонуси перетворяться на реальну
              цінність.
            </p>
            <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
              Реалізуйте свої ідеї за допомогою об'єднаних зусиль та ресурсів.
            </p>
            <p className='c-dark flex jcsb p-y-12 m-y-0'>
              Розподіляйте прибуток чесно та прозоро між усіма учасниками.
            </p>
          </div>
          <div className='col-lg-5'>
            <h4 className='c-dark fw-600 upp'>
              Переваги використання нашої платформи
            </h4>
            <div className='s-24'></div>
            <div className='s-1 bg-lite'></div>
            <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
              Прозорість у відносинах між учасниками проекту.
            </p>
            <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
              Захист від конфліктів при розподілі прибутку.
            </p>
            <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
              Можливість залучення додаткових ресурсів та талантів.
            </p>
            <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
              Стимулювання команди через спільну мету та винагороду.
            </p>
            <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
              Ефективне управління за допомогою інновацій.
            </p>
            <p className='c-dark flex jcsb p-y-12 m-y-0'>
              Підвищення довіри інвесторів завдяки прозорим процесам.
            </p>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-12 tc p-y-40'>
          <a href='/' className='button px-5'>
            Розпочати Зараз
          </a>
        </div>
      </div>
      <div className='bg-white'>
        <div className='container'>
          <div className='s-20'></div>
          <div className='row'>
            <div className='col-lg-6 py-3'>
              <a href='/' className='c-dark title-mini'>
                © 2024 - eBonus. Всi права захищено.
              </a>
            </div>
            <div className='col-lg-6 c-dark title-mini tr py-3'>
              <a href='/cookie' className='mx-3'>
                Полiтика Cookie
              </a>
              <a href='/privacy' className='mx-3'>
                Полiтика Конфiденцiйностi
              </a>
            </div>
          </div>
          <div className='s-20'></div>
        </div>
      </div>
    </footer>
  );
};

export { Footer };
