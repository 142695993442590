import React from 'react';
import { NavPages } from '../components/NavPages';
import { Footer } from '../components/Footer';
import posts from '../data/posts';
import { Link } from 'react-router-dom';
import { createSlug } from '../components/utils';

const Blog = () => {
  return (
    <>
      <NavPages />
      <div className='page'>
        <div className='container'>
          <div className='flex jcsb aic'>
            <h2 className='page-title w-30'>Корисні Статті</h2>
            <p className='title c-dark w-50 fw-400 fz-14 lh-125'>
              Дізнавайтеся про нові можливості платформи, успішні історії
              користувачів та отримуйте цінні поради для розвитку ваших проектів
            </p>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='s-60'></div>
        <div className='row'>
          {posts.map((post) => {
            const slug = createSlug(post.title);
            return (
              <div className='col-lg-4 m-b-48' key={post.id}>
                <Link to={`/blog/${slug}`}>
                  <img
                    src={post.pic}
                    alt={post.title}
                    width={420}
                    height={240}
                    className='r-4 obfc w-100'
                  />
                </Link>
                <div className='s-8'></div>
                <h4 className='c-dark title p-t-8 fw-600'>{post.title}</h4>
                <div className='s-8'></div>
                <p className='c-dark lh-1'>{post.date}</p>
                <p className='c-dark py-1 fw-400 hv-7'>{post.summary}</p>
                <div className='s-20'></div>
                <div className='flex aic'>
                  <Link to={`/blog/${slug}`} className='button'>
                    Переглянути
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </>
  );
};

export { Blog };
