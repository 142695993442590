import React from 'react';
import { NavPages } from '../components/NavPages';
import { Footer } from '../components/Footer';

const Reliability = () => {
  return (
    <>
      <NavPages />
      <div className='page'>
        <div className='container'>
          <div className='flex jcsb aic'>
            <h2 className='page-title w-30'>Надійність</h2>
            <p className='title c-dark w-50 fw-400 fz-14 lh-125'>
              Ми розуміємо важливість довіриб тому ми створили платформу яка
              забезпечує надійність для вашого успіху.
            </p>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='s-100'></div>
        <div className='row'>
          <div className='col-lg-6'>
            <h4 className='c-dark fw-600 upp'>Наші гарантії</h4>
            <div className='s-24'></div>
            <div className='s-1 bg-lite'></div>
            <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
              🔒 Безпека даних: Ми використовуємо сучасні технології шифрування
              для захисту вашої інформації.
            </p>
            <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
              ✅ Прозорість угод: Усі умови співпраці чітко задокументовані та
              доступні для перегляду в будь-який час.
            </p>
            <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
              🤝 Захист від конфліктів: Наша система передбачає механізми
              вирішення спірних ситуацій для забезпечення гармонійної співпраці.
            </p>
            <p className='c-dark flex jcsb p-y-12 m-y-0'>
              📞 Підтримка 24/7: Наша команда готова допомогти вам у будь-який
              час з будь-яких питань.
            </p>
          </div>
          <div className='col-lg-6'>
            <h4 className='c-dark fw-600 upp'>Чому нам довіряють</h4>
            <div className='s-24'></div>
            <div className='s-1 bg-lite'></div>
            <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
              👥 Спільнота професіоналів: На нашій платформі зібрані надійні
              партнери та інвестори.
            </p>
            <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
              🌐 Глобальний досвід: Ми працюємо з користувачами з різних країн,
              що підтверджує нашу міжнародну репутацію.
            </p>
            <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
              🔄 Постійне вдосконалення: Ми регулярно оновлюємо платформу,
              впроваджуючи новітні технології та функції.
            </p>
            <p className='c-dark fw-500 flex jcsb p-y-12 m-y-0'>
              Готові впевнено крокувати до успіху разом з нами?
            </p>
          </div>
        </div>
      </div>
      <div className='s-40'></div>
      <Footer />
    </>
  );
};

export { Reliability };
