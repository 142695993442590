import React from 'react';
import { Nav } from '../../components/Nav';
import pic from '../../assets/intro-pic.svg';

const Intro = () => {
  return (
    <section id='top'>
      <Nav />
      <div className='flex aic jcc w-100 hv-100'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-7'>
              <div className='flex aic hv-100'>
                <div>
                  <h2 className='title-intro c-white fw-600 lh-115'>
                    Платформа мотивації та розподілу ролей
                  </h2>
                  <div className='s-10'></div>
                  <h1 className='title-intro-sub c-white p-r-40'>
                    Підвищуємо ефективність команд через прозорість та мотивацію
                  </h1>
                  <div className='s-30'></div>
                  <div className='flex aic jcfs w-54'>
                    <a className='button first px-5' href='#form'>
                      Розпочати
                    </a>
                    <a href='#form' className='button outline px-5'>
                      Подробиці
                    </a>
                  </div>
                  <div className='s-120'></div>
                  <div className='s-20'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='bg-intro'>
        <div className='flex jcfe'>
          <img
            src={pic}
            alt={'pc'}
            width={'50%'}
            height={'100%'}
            style={{
              width: '50%',
              height: '100vh',
              objectFit: 'cover',
              zIndex: 99,
            }}
          />
        </div>
      </div>
    </section>
  );
};

export { Intro };
