import React from 'react';
import { NavPages } from '../components/NavPages';
import { Footer } from '../components/Footer';
import { createSlug } from '../components/utils';
import { useParams } from 'react-router-dom';
import projects from '../data/projects';

const SingleProject = () => {
  const { slug } = useParams();

  const el = projects.find((p) => createSlug(p.title) === slug);

  if (!el) {
    return '';
  }

  return (
    <>
      <NavPages />
      <div className='page'>
        <div className='container'>
          <div className='flex jcsb aic'>
            <div className='flex aic'>
              <img src={el.pic} alt={el.title} width={40} height={40} />
              <div className='wp-16'></div>
              <h2 className='page-title w-30'>{el.title}</h2>
            </div>
            <p className='title c-dark w-47 fw-400 fz-14 lh-135'>
              {el.pr.slogan}
            </p>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='s-60'></div>
        <div className='row'>
          <div className='col-lg-4 p-r-24'>
            <h4 className='c-theme title-intro-sub fw-600 upp'>
              {el.pr.title}
            </h4>
            <div className='s-8'></div>
            <p className='c-dark'>{el.pr.desc}</p>
            <img
              src={el.pr.image}
              alt={el.title}
              width={640}
              height={'auto'}
              className='w-100 obfsd'
              style={{ objectPosition: 'top' }}
            />
          </div>
          <div className='col-lg-8'>
            <div className='p-x-24'>
              <p className='title c-dark fw-500 p-t-4'>Проблема та рішення:</p>
              <div className='s-14'></div>
              <div className='s-1 bg-lite'></div>
              <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
                <span className='fw-500 c-light'>Проблема</span>
              </p>
              <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
                {el.pr.problem[0]}
              </p>
              <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
                {el.pr.problem[1]}
              </p>
              <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
                {el.pr.problem[2]}
              </p>
              <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
                <span className='fw-500 c-light'>Рішення</span>
              </p>
              <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
                {el.pr.solve[0]}
              </p>
              <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
                {el.pr.solve[1]}
              </p>
              <p className='c-dark flex jcsb p-y-12 m-y-0'>{el.pr.solve[2]}</p>
            </div>
          </div>
        </div>
        <div className='s-24'></div>
        <div className='s-1 bg-lite'></div>
        <div className='s-40'></div>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='p-x-24 bg-lite p-y-20'>
              <p className='title c-dark fw-500 p-t-4'>Ключові особливості</p>
              <div className='s-14'></div>
              <div className='s-1 bg-lite'></div>
              <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
                {el.pr.features[0]}
              </p>
              <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
                {el.pr.features[1]}
              </p>
              <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
                {el.pr.features[2]}
              </p>
              <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
                {el.pr.features[3]}
              </p>
              <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
                {el.pr.features[4]}
              </p>
            </div>
          </div>
          <div className='col-lg-8'>
            <div className='p-x-24 p-t-16'>
              <h3 className='title c-dark fw-500 p-t-4'>Ролі RACI у проекті</h3>
              <div className='s-16'></div>
              <div className='s-2 bg-lite'></div>
              <div className='s-16'></div>
              <div className='row'>
                <div className='col-lg-7 p-r-24'>
                  <h4 className='fw-500 title c-light flex jcsb p-y-12 m-y-0 bw-1 bb'>
                    A (Accountable) — Відповідальний:
                  </h4>
                  <p className='c-dark p-t-12'>{el.pr.accountable[0]}</p>
                  <h4 className='fw-500 title c-light flex jcsb p-y-12 m-y-0 bw-1 bb'>
                    C (Consulted) — Консультант:
                  </h4>
                  <p className='c-dark p-t-12'>{el.pr.consulted[0]}</p>
                  <h4 className='fw-500 title c-light flex jcsb p-y-12 m-y-0 bw-1 bb'>
                    I (Informed) — Поінформовані:
                  </h4>
                  <p className='c-dark p-t-12'>{el.pr.informed[0]}</p>
                  <p className='c-dark'>{el.pr.informed[1]}</p>
                </div>
                <div className='col-lg-5'>
                  <h4 className='fw-500 title c-light flex jcsb p-y-12 m-y-0 bw-1 bb'>
                    R (Responsible) — Виконавець:
                  </h4>
                  <p className='c-dark p-t-12'>{el.pr.responsible[0]}</p>
                  <p className='c-dark'>{el.pr.responsible[1]}</p>
                  <p className='c-dark'>{el.pr.responsible[2]}</p>
                  <p className='c-dark'>{el.pr.responsible[3]}</p>
                  <p className='c-dark'>{el.pr.responsible[4]}</p>
                  <p className='c-dark'>{el.pr.responsible[5]}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='s-40'></div>
        <div className='s-1 bg-lite'></div>
        <div className='s-40'></div>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='p-x-24 bg-lite p-y-20'>
              <p className='title c-dark fw-500 p-t-4'>Фаундер</p>
              <div className='s-14'></div>
              <div className='s-1 bg-lite'></div>
              <p className='c-dark flex jcsb p-y-8 m-y-0 bw-1 bb'>{el.name}</p>
              <div className='s-14'></div>
              <h3 className='title c-dark fw-500 p-t-4'>Досвід</h3>
              <div className='s-14'></div>
              <div className='s-2 bg-lite'></div>
              <p className='c-dark flex jcsb p-y-8 m-y-0 bw-1 bb'>
                {el.pr.founder_skills[0]}
              </p>
              <p className='c-dark flex jcsb p-y-8 m-y-0 bw-1 bb'>
                {el.pr.founder_skills[1]}
              </p>
              <p className='c-dark flex jcsb p-y-8 m-y-0 bw-1 bb'>
                {el.pr.founder_skills[2]}
              </p>
              <div className='s-14'></div>
              <h3 className='title c-dark fw-500 p-t-4'>Місія</h3>
              <div className='s-14'></div>
              <div className='s-2 bg-lite'></div>
              <p className='c-dark flex jcsb p-y-8 m-y-0 bw-1 bb'>
                {el.pr.founder_mission}
              </p>
            </div>
          </div>
          <div className='col-lg-8'>
            <div className='p-x-24 p-t-8'>
              <h3 className='title c-dark fw-500 p-t-4'>Ролі RACI у проекті</h3>
              <div className='s-16'></div>
              <div className='s-2 bg-lite'></div>
              <div className='s-16'></div>
              <div className='row'>
                <div className='col-lg-7 p-r-24'>
                  <h4 className='fw-500 title c-light flex jcsb p-y-12 m-y-0 bw-1 bb'>
                    A (Accountable) — Відповідальний:
                  </h4>
                  <p className='c-dark p-t-12'>{el.pr.accountable[0]}</p>
                  <h4 className='fw-500 title c-light flex jcsb p-y-12 m-y-0 bw-1 bb'>
                    C (Consulted) — Консультант:
                  </h4>
                  <p className='c-dark p-t-12'>{el.pr.consulted[0]}</p>
                  <h4 className='fw-500 title c-light flex jcsb p-y-12 m-y-0 bw-1 bb'>
                    I (Informed) — Поінформовані:
                  </h4>
                  <p className='c-dark p-t-12'>{el.pr.informed[0]}</p>
                  <p className='c-dark'>{el.pr.informed[1]}</p>
                </div>
                <div className='col-lg-5'>
                  <h4 className='fw-500 title c-light flex jcsb p-y-12 m-y-0 bw-1 bb'>
                    R (Responsible) — Виконавець:
                  </h4>
                  <p className='c-dark p-t-12'>{el.pr.responsible[0]}</p>
                  <p className='c-dark'>{el.pr.responsible[1]}</p>
                  <p className='c-dark'>{el.pr.responsible[2]}</p>
                  <p className='c-dark'>{el.pr.responsible[3]}</p>
                  <p className='c-dark'>{el.pr.responsible[4]}</p>
                  <p className='c-dark'>{el.pr.responsible[5]}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='s-40'></div>
        <div className='s-1 bg-lite'></div>
        <div className='s-40'></div>
        <div className='row'>
          <div className='col-lg-6'>
            <div className='p-x-24 p-y-20 bg-marine'>
              <p className='title c-white fw-600 p-t-4'>Приєднуйся до нас!</p>
              <div className='s-14'></div>
              <p className='c-white fz-15 fw-500 p-t-4'>
                Чому варто взяти участь у проекті {el.title}
              </p>
              <p className='c-white flex jcsb p-y-8 m-y-0'>{el.pr.join[0]}</p>
              <p className='c-white flex jcsb p-y-8 m-y-0'>{el.pr.join[1]}</p>
              <p className='c-white flex jcsb p-y-8 m-y-0'>{el.pr.join[2]}</p>
              <p className='c-white flex jcsb p-y-8 m-y-0'>{el.pr.join[3]}</p>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='p-x-24 p-t-16'>
              <div className='row'>
                <div className='col-lg-6'>
                  <h3 className='title c-dark fw-500 p-t-4'>
                    Як приєднатися до команди
                  </h3>
                  <div className='s-16'></div>
                  <div className='s-2 bg-lite'></div>
                  <div className='s-16'></div>
                  <p className='c-dark p-t-12'>
                    1. Надішли резюме або портфоліо на електронну пошту:{' '}
                    {el.pr.email}
                  </p>
                  <p className='c-dark'>
                    2. Розкажи про свій досвід та мотивацію у супровідному
                    листі.
                  </p>
                  <p className='c-dark'>
                    3. Пройди онлайн-співбесіду з фаундером для обговорення
                    деталей.
                  </p>
                </div>
                <div className='col-lg-6 p-l-40'>
                  <h3 className='title c-dark fw-500 p-t-4'>
                    Контактна інформація
                  </h3>
                  <div className='s-16'></div>
                  <div className='s-2 bg-lite'></div>
                  <div className='s-16'></div>
                  <p className='c-dark p-t-12'>Email: {el.pr.email}</p>
                  <p className='c-dark'>
                    Телефон: <a href='tel:{el.pr.phone}'>+{el.pr.phone}</a>
                  </p>
                  <p className='c-dark'>Telegram: @{el.pr.telegram}</p>
                  <p className='c-dark'>
                    LinkedIn: https://linkedin.com/in/{el.pr.linkedin}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='s-40'></div>
        <div className='s-1 bg-lite'></div>
        <div className='s-40'></div>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='p-x-24 bg-lite p-y-20'>
              <p className='title-sub c-dark fw-500 p-t-4'>Деталі проекту</p>
              <div className='s-14'></div>
              <h3 className='title c-dark fw-500'>Цільова аудиторія</h3>
              <div className='s-14'></div>
              <div className='s-2 bg-lite'></div>
              <p className='c-dark flex jcsb p-y-8 m-y-0 bw-1 bb'>
                {el.pr.audience[0]}
              </p>
              <p className='c-dark flex jcsb p-y-8 m-y-0 bw-1 bb'>
                {el.pr.audience[1]}
              </p>
              <p className='c-dark flex jcsb p-y-8 m-y-0 bw-1 bb'>
                {el.pr.audience[2]}
              </p>
              <div className='s-14'></div>
              <h3 className='title c-dark fw-500 p-t-4'>План розвитку</h3>
              <div className='s-14'></div>
              <div className='s-2 bg-lite'></div>
              <p className='c-dark flex jcsb p-y-8 m-y-0 bw-1 bb'>
                {el.pr.plan[0]}
              </p>
              <p className='c-dark flex jcsb p-y-8 m-y-0 bw-1 bb'>
                {el.pr.plan[1]}
              </p>
              <p className='c-dark flex jcsb p-y-8 m-y-0 bw-1 bb'>
                {el.pr.plan[2]}
              </p>
            </div>
          </div>
          <div className='col-lg-8'>
            <div className='p-x-24 p-t-4'>
              <h3 className='title c-dark fw-500 p-t-8'>
                Що ми шукаємо в кандидатах
              </h3>
              <div className='s-16'></div>
              <div className='s-2 bg-lite'></div>
              <div className='s-16'></div>
              <div className='row'>
                <div className='col-lg-6 p-r-20'>
                  <h4 className='fw-500 title c-light flex jcsb p-y-12 m-y-0 bw-1 bb'>
                    {el.pr.candidates[0].title}
                  </h4>
                  <p className='c-dark p-t-12 my-1'>
                    {el.pr.candidates[0].skills[0]}
                  </p>
                  <p className='c-dark my-1'>{el.pr.candidates[0].skills[1]}</p>
                  <div className='s-16'></div>
                  <h4 className='fw-500 title c-light flex jcsb p-b-12 m-y-0 bw-1 bb'>
                    {el.pr.candidates[1].title}
                  </h4>
                  <p className='c-dark p-t-12 my-1'>
                    {el.pr.candidates[1].skills[0]}
                  </p>
                  <p className='c-dark my-1'>{el.pr.candidates[1].skills[1]}</p>
                  <div className='s-16'></div>
                  <h4 className='fw-500 title c-light flex jcsb p-b-12 m-y-0 bw-1 bb'>
                    {el.pr.candidates[2].title}
                  </h4>
                  <p className='c-dark p-t-12 my-1'>
                    {el.pr.candidates[2].skills[0]}
                  </p>
                  <p className='c-dark my-1'>{el.pr.candidates[2].skills[1]}</p>
                </div>
                <div className='col-lg-6 p-l-20'>
                  <h4 className='fw-500 title c-light flex jcsb p-y-12 m-y-0 bw-1 bb'>
                    {el.pr.candidates[3].title}
                  </h4>
                  <p className='c-dark p-t-12 my-1'>
                    {el.pr.candidates[3].skills[0]}
                  </p>
                  <p className='c-dark my-1'>{el.pr.candidates[3].skills[1]}</p>
                  <div className='s-16'></div>
                  <h4 className='fw-500 title c-light flex jcsb p-b-12 m-y-0 bw-1 bb'>
                    {el.pr.candidates[4].title}
                  </h4>
                  <p className='c-dark p-t-12 my-1'>
                    {el.pr.candidates[4].skills[0]}
                  </p>
                  <p className='c-dark my-1'>{el.pr.candidates[4].skills[1]}</p>
                  <div className='s-16'></div>
                  <h4 className='fw-500 title c-light flex jcsb p-b-12 m-y-0 bw-1 bb'>
                    {el.pr.candidates[5].title}
                  </h4>
                  <p className='c-dark p-t-12 my-1'>
                    {el.pr.candidates[5].skills[0]}
                  </p>
                  <p className='c-dark my-1'>{el.pr.candidates[5].skills[1]}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='s-40'></div>
        <div className='s-1 bg-lite'></div>
        <div className='s-40'></div>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='p-x-24 bg-lite p-y-20'>
              <p className='title-sub c-dark fw-500 p-t-4'>
                Переваги участі у проекті
              </p>
              <div className='s-14'></div>
              <p className='c-dark flex jcsb p-y-8 m-y-0 bw-1 bb'>
                {el.pr.advantages[0]}
              </p>
              <p className='c-dark flex jcsb p-y-8 m-y-0 bw-1 bb'>
                {el.pr.advantages[1]}
              </p>
              <p className='c-dark flex jcsb p-y-8 m-y-0 bw-1 bb'>
                {el.pr.advantages[2]}
              </p>
            </div>
          </div>
          <div className='col-lg-8'>
            <div className='p-x-24 p-t-16'>
              <div className='row'>
                <div className='col-lg-7'>
                  <h3 className='title c-dark fw-500 p-t-4'>
                    Додаткова інформація
                  </h3>
                  <div className='s-16'></div>
                  <div className='s-2 bg-lite'></div>
                  <div className='s-16'></div>
                  <p className='c-dark p-t-12'>{el.pr.additional[0]}</p>
                  <p className='c-dark'>{el.pr.additional[1]}</p>
                  <p className='c-dark'>{el.pr.additional[2]}</p>
                </div>
                <div className='col-lg-5 p-l-40'>
                  <h3 className='title c-dark fw-500 p-t-4'>
                    Соціальні мережі
                  </h3>
                  <div className='s-16'></div>
                  <div className='s-2 bg-lite'></div>
                  <div className='s-16'></div>
                  <p className='c-dark p-t-12'>
                    <strong>{el.pr.social[0].title}</strong>
                    <br />
                    {el.pr.social[1].url}
                  </p>
                  <p className='c-dark'>
                    <strong>{el.pr.social[1].title}</strong>
                    <br />
                    {el.pr.social[1].url}
                  </p>
                  <p className='c-dark'>
                    <strong>{el.pr.social[2].title}</strong>
                    <br />
                    {el.pr.social[2].url}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='s-40'></div>
        <div className='s-1 bg-lite'></div>
        <div className='s-40'></div>
        <div className='row'>
          <div className='col-lg-8'>
            <div className='p-x-24 p-y-20'>
              <h3 className='title-sub c-light fw-500 upp p-t-4'>
                Питання та відповіді (FAQ):
              </h3>
              <div className='s-16'></div>
              <div className='s-2 bg-lite'></div>
              <div className='s-16'></div>
              <p className='c-dark p-t-4 fw-500'>{el.pr.faq[0].qs}</p>
              <p className='c-dark'>{el.pr.faq[0].an}</p>
              <p className='c-dark p-t-4 fw-500'>{el.pr.faq[1].qs}</p>
              <p className='c-dark'>{el.pr.faq[1].an}</p>
              <p className='c-dark p-t-4 fw-500'>{el.pr.faq[2].qs}</p>
              <p className='c-dark'>{el.pr.faq[2].an}</p>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='s-70'></div>
            <div className='p-x-48 p-y-48 bg-marine'>
              <p className='title c-white fw-500 p-t-4'>{el.pr.cta}</p>
              <div className='s-24'></div>
              <h3 className='title c-white fw-400 p-t-4'>
                Підпишись на новини проекту:
              </h3>
              <div className='s-20'></div>
              <a href='/' className='button'>
                Підписатися
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='s-40'></div>
      <Footer />
    </>
  );
};

export { SingleProject };
