import React from 'react';
import { NavPages } from '../components/NavPages';
import { Footer } from '../components/Footer';
import projects from '../data/projects';
import { Link } from 'react-router-dom';
import { createSlug } from '../components/utils';

const Projects = () => {
  return (
    <>
      <NavPages />
      <div className='page'>
        <div className='container'>
          <div className='flex jcsb aic'>
            <h2 className='page-title w-30'>Вітрина Проектів</h2>
            <p className='title c-dark w-50 fw-400 fz-14 lh-125'>
              Перегляньте проекти на нашій платформі та ідкрийте нові ідеї та
              можливості для інвестування та розвитку проектів
            </p>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='s-60'></div>
        <div className='row'>
          {projects.map((project) => {
            const slug = createSlug(project.title);
            return (
              <div className='col-lg-4 m-b-24' key={project.id}>
                <div className='p-x-48 p-y-40 gallery project r-16 w-100'>
                  <div className='flex aic'>
                    <img
                      src={project.pic}
                      alt={project.title}
                      width={48}
                      height={48}
                      className='m-b-8 m-r-16'
                    />
                    <div>
                      <h4 className='c-dark title-sub p-t-8 lh-1 '>
                        {project.title}
                      </h4>
                      <p className='c-dark lh-1'>{project.name}</p>
                    </div>
                  </div>
                  <p className='c-dark py-1 title-sub fw-400 hv-18'>
                    {project.desc}
                  </p>
                  <div className='flex jcsb'>
                    <div className='flex aife'>
                      <p className='c-dark p-b-2'>Бонус:</p>
                      <span className='w-10'></span>
                      <p className='c-dark title-sub'>{project.bonus}</p>
                    </div>
                    <span className='c-dark border br-4 bc-dark r-4 px-2 hp-26'>
                      {project.status}
                    </span>
                  </div>
                  <div className='avatars flex'>
                    {project.avatars.map((avatar, j) => (
                      <img
                        key={j}
                        src={avatar}
                        alt='avatar'
                        width={32}
                        height={32}
                      />
                    ))}
                  </div>
                  <div className='s-20'></div>
                  <div className='flex aic'>
                    <Link to={'/'} className='button first mini wp-124'>
                      Приєднатися
                    </Link>
                    <Link
                      to={project.pr ? `/projects/${slug}` : '/'}
                      className='button mini project wp-124'
                    >
                      Подробиці
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className='s-60'></div>
      <Footer />
    </>
  );
};

export { Projects };
