import React from 'react';
import { NavPages } from '../components/NavPages';
import { Footer } from '../components/Footer';
import pic1 from '../assets/how/pic1.jpg';
import pic2 from '../assets/how/pic2.jpg';
import pic4 from '../assets/how/pic4.jpg';

const HowItWorks = () => {
  return (
    <>
      <NavPages />
      <div className='page'>
        <div className='container'>
          <div className='flex jcsb aic'>
            <h2 className='page-title w-30'>Як це працює</h2>
            <p className='title c-dark w-50 fw-400 fz-14 lh-125'>
              Дізнайтеся, як наша платформа перетворює ідеї на проекти: стартап,
              бонуси, інвестори, мотивація.
            </p>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='s-60'></div>
        <div className='row'>
          <div className='col-lg-4 p-r-24'>
            <h4 className='c-theme title-intro-sub fw-600 upp'>
              Створення віртуального стартапу
            </h4>
            <div className='s-24'></div>
            <p className='title c-dark'>
              Марія має інноваційну ідею для мобільного додатку, але не має
              достатньо ресурсів для його реалізації.
            </p>
            <div className='s-30'></div>
            <img
              src={pic1}
              alt='Створення віртуального стартапу'
              width={'100%'}
              height={240}
              className='w-100 hp-240 obfc r-4'
            />
          </div>
          <div className='col-lg-8'>
            <div className='p-x-24'>
              <p className='title c-dark fw-500 p-t-4'>Дії на платформі:</p>
              <div className='s-14'></div>
              <div className='s-1 bg-lite'></div>
              <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
                <span className='w-25 fw-500 c-light'>Реєстрація</span>
                <span className='w-73'>
                  Марія реєструє віртуальну компанію на нашій платформі.
                </span>
              </p>
              <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
                <span className='w-25 fw-500 c-light'>Випуск бонусів</span>
                <span className='w-73'>
                  Вона випускає віртуальні бонуси, що символізують частку в
                  майбутньому прибутку.
                </span>
              </p>
              <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
                <span className='w-25 fw-500 c-light'>Розподіл бонусів</span>
                <span className='w-73'>
                  Марія роздає бонуси розробникам та дизайнерам, які
                  приєднуються до проекту.
                </span>
              </p>
              <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
                <span className='w-25 fw-500 c-light'>Декларування</span>
                <span className='w-73'>
                  Вона публікує умови, за якими бонуси стануть реальними
                  прибутками для учасників.
                </span>
              </p>
              <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
                <span className='w-25 fw-500 c-light'>Реалізація проекту</span>
                <span className='w-73'>
                  Команда працює над створенням додатку.
                </span>
              </p>
              <p className='c-dark flex jcsb p-y-12 m-y-0'>
                <span className='w-25 fw-500 c-light'>Отримання прибутку</span>
                <span className='w-73'>
                  Після запуску додатку та отримання прибутку, він
                  розподіляється між учасниками згідно з бонусами.
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className='s-40'></div>
        <div className='s-1 bg-lite'></div>
        <div className='s-40'></div>
        <div className='row'>
          <div className='col-lg-4 p-r-24'>
            <h4 className='c-theme title-intro-sub fw-600 upp'>
              Залучення інвесторів
            </h4>
            <div className='s-24'></div>
            <p className='title c-dark'>
              Олексій розробляє технологічний гаджет і потребує фінансування для
              виробництва.
            </p>
            <div className='s-30'></div>
            <img
              src={pic2}
              alt='Створення віртуального стартапу'
              width={'100%'}
              height={240}
              className='w-100 hp-240 obfc r-4'
            />
          </div>
          <div className='col-lg-8'>
            <div className='p-x-24'>
              <p className='title c-dark fw-500 p-t-4'>Дії на платформі:</p>
              <div className='s-14'></div>
              <div className='s-1 bg-lite'></div>
              <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
                <span className='w-25 fw-500 c-light'>Реєстрація</span>
                <span className='w-73'>
                  Олексій створює віртуальну компанію на платформі.
                </span>
              </p>
              <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
                <span className='w-25 fw-500 c-light'>Випуск бонусів</span>
                <span className='w-73'>
                  Він випускає віртуальні бонуси для потенційних інвесторів.
                </span>
              </p>
              <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
                <span className='w-25 fw-500 c-light'>Розподіл бонусів</span>
                <span className='w-73'>
                  Олексій пропонує бонуси інвесторам в обмін на фінансування.
                </span>
              </p>
              <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
                <span className='w-25 fw-500 c-light'>Декларування</span>
                <span className='w-73'>
                  Він встановлює умови конвертації бонусів у реальні акції або
                  прибуток.
                </span>
              </p>
              <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
                <span className='w-25 fw-500 c-light'>
                  Виробництво продукту
                </span>
                <span className='w-73'>
                  Залучені кошти використовуються для запуску виробництва.
                </span>
              </p>
              <p className='c-dark flex jcsb p-y-12 m-y-0'>
                <span className='w-25 fw-500 c-light'>Розподіл прибутку</span>
                <span className='w-73'>
                  Прибуток від продажів розподіляється між Олексієм та
                  інвесторами.
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className='s-40'></div>
        <div className='s-1 bg-lite'></div>
        <div className='s-40'></div>
        <div className='row'>
          <div className='col-lg-4 p-r-24'>
            <h4 className='c-theme title-intro-sub fw-600 upp'>
              Мотивація команди
            </h4>
            <div className='s-24'></div>
            <p className='title c-dark'>
              Компанія "Інновації ХХІ" хоче стимулювати співробітників до
              розробки нового продукту.
            </p>
            <div className='s-30'></div>
            <img
              src={pic4}
              alt='Створення віртуального стартапу'
              width={'100%'}
              height={240}
              className='w-100 hp-240 obfc r-4'
            />
          </div>
          <div className='col-lg-8'>
            <div className='p-x-24'>
              <p className='title c-dark fw-500 p-t-4'>Дії на платформі:</p>
              <div className='s-14'></div>
              <div className='s-1 bg-lite'></div>
              <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
                <span className='w-25 fw-500 c-light'>Реєстрація</span>
                <span className='w-73'>
                  Компанія реєструє віртуальний проект на платформі.
                </span>
              </p>
              <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
                <span className='w-25 fw-500 c-light'>Випуск бонусів</span>
                <span className='w-73'>
                  Випускаються віртуальні бонуси для співробітників.
                </span>
              </p>
              <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
                <span className='w-25 fw-500 c-light'>Розподіл бонусів</span>
                <span className='w-73'>
                  Бонуси роздаються членам команди на основі їх вкладу в проект.
                </span>
              </p>
              <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
                <span className='w-25 fw-500 c-light'>Декларування</span>
                <span className='w-73'>
                  Встановлюються чіткі умови отримання реальних винагород.
                </span>
              </p>
              <p className='c-dark flex jcsb p-y-12 m-y-0 bw-1 bb'>
                <span className='w-25 fw-500 c-light'>Розробка продукту</span>
                <span className='w-73'>
                  Команда активно працює над створенням продукту.
                </span>
              </p>
              <p className='c-dark flex jcsb p-y-12 m-y-0'>
                <span className='w-25 fw-500 c-light'>
                  Винагорода співробітників
                </span>
                <span className='w-73'>
                  Після успішного запуску співробітники отримують частку
                  прибутку.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='s-40'></div>
      <Footer />
    </>
  );
};

export { HowItWorks };
