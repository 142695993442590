import { transliterate as tr } from 'transliteration';

function createSlug(title) {
  return tr(title)
    .replace(/\s+/g, '-')
    .replace(/[^a-zA-Z0-9-]/g, '')
    .toLowerCase();
}

export { createSlug };
