import pr0 from '../assets/pr0.svg';
import pr1 from '../assets/pr1.svg';
import pr2 from '../assets/pr2.svg';
import pr3 from '../assets/pr3.svg';

import pr11 from '../assets/pr1-01.png';
import pr12 from '../assets/pr1-02.png';
import pr13 from '../assets/pr1-03.png';
import pr14 from '../assets/pr1-04.png';
import pr15 from '../assets/pr1-05.png';

import pr21 from '../assets/pr2-01.png';
import pr22 from '../assets/pr2-02.png';
import pr23 from '../assets/pr2-03.png';

import pr31 from '../assets/pr3-01.png';
import pr32 from '../assets/pr3-02.png';
import pr33 from '../assets/pr3-03.png';
import pr34 from '../assets/pr3-04.png';

import pr4 from '../assets/pr4.svg';
import pr5 from '../assets/pr5.svg';
import pr6 from '../assets/pr6.svg';
import pr7 from '../assets/pr7.svg';
import pr8 from '../assets/pr8.svg';

import pr_pr0 from '../assets/projects/ebonus.jpg';

const projects = [
  {
    id: 0,
    title: 'eBonus',
    name: 'Андрій Роговский',
    desc: 'Платформа мотивації та розподілу ролей RACI',
    bonus: 25000,
    status: 'Активний',
    avatars: [pr11, pr12, pr13, pr14, pr15],
    pic: pr0,
    pr: {
      title: 'Платформа мотивації та розподілу ролей RACI',
      slogan: 'Підвищуємо ефективність команд через прозорість та мотивацію',
      image: pr_pr0,
      desc: 'eBonus — інноваційна платформа, створена для підвищення залученості команд та оптимізації робочих процесів. Ми поєднуємо систему віртуальних бонусів із матрицею RACI, щоб забезпечити прозорість ролей та мотивувати учасників на досягнення кращих результатів.',
      problem: [
        'Відсутність мотивації у співробітників через непрозорість винагород.',
        'Хаос та дублювання функцій через нечіткий розподіл ролей.',
        'Затримки у проектах через довгі погодження та неефективну комунікацію.',
      ],
      solve: [
        'Впровадження системи еБонусів, які нараховуються за своєчасне та якісне виконання завдань.',
        'Використання матриці RACI для чіткого розподілу ролей та відповідальності.',
        'Автоматизація процесів оцінки та винагороди, скорочення ланцюгів погоджень.',
      ],
      features: [
        'Прозорість винагород: Кожен учасник бачить, за що і скільки бонусів він може отримати.',
        'Чіткий розподіл ролей: Матриця RACI інтегрована в завдання, усуваючи дублювання функцій.',
        'Автоматизація нарахувань: Система автоматично розраховує бонуси за підсумками кожного спринту.',
        'Звітність для фаундерів: Детальні звіти про ефективність команди та прогрес проектів.',
        'Гейміфікація: Рейтинги та досягнення стимулюють здорову конкуренцію.',
      ],
      roles_desc:
        'На даний момент команда складається лише з фаундера, тому ми шукаємо спеціалістів для заповнення наступних ролей:',
      responsible: [
        'Frontend-розробник',
        'Backend-розробник',
        'UI/UX-дизайнер',
        'QA-інженер',
        'DevOps-інженер',
        'Спеціаліст з маркетингу',
      ],
      accountable: [
        'Фаундер (Андрій Роговский) — загальна відповідальність за проект.',
      ],
      consulted: [
        'Ментори та експерти галузі (планується залучити після формування команди).',
      ],
      informed: [
        'Інвестори (будуть поінформовані про прогрес після створення MVP).',
        'Потенційні користувачі (отримуватимуть оновлення через соціальні мережі та розсилки).',
      ],
      founder_skills: [
        '20 років в управлінні IT-проектами',
        'Експерт у сфері DevOps та DevSecOps',
        'Успішна реалізація більш ніж 400 проектів у сфері ІТ',
      ],
      founder_mission: [
        'Створити платформу, яка змінить підхід до мотивації та управління командами, роблячи процеси прозорими та ефективними.',
      ],
      join: [
        'Інноваційний продукт: Бери участь у створенні платформи, що здатна змінити індустрію управління проектами.',
        'Професійний розвиток: Отримай унікальний досвід роботи з новітніми технологіями та методологіями.',
        'Гнучкі умови: Можливість віддаленої роботи та гнучкого графіка.',
        'Вплив на продукт: Твої ідеї та пропозиції будуть почуті та реалізовані.',
      ],
      email: 'admin@h4w.com',
      phone: '380671234567',
      telegram: 'h4w',
      linkedin: 'h4w',
      audience: [
        'Малі та середні IT-компанії',
        'Стартапи',
        'Команди розробників, що бажають підвищити ефективність',
      ],
      plan: [
        'Розробка MVP з базовими функціями',
        "Тестування та доопрацювання платформи на основі зворотного зв'язку",
        'Запуск на ринок та масштабування',
      ],
      candidates: [
        {
          id: 1,
          title: 'Frontend-розробник (R)',
          skills: ['Досвід роботи з React.js', 'Знання HTML, CSS, JavaScript'],
        },
        {
          id: 2,
          title: 'Backend-розробник (R)',
          skills: [
            'Досвід роботи з Node.js та Express.js',
            'Знання RESTful API, баз даних',
          ],
        },
        {
          id: 3,
          title: 'UI/UX-дизайнер (R)',
          skills: [
            'Навички прототипування та розробки інтерфейсів',
            'Розуміння принципів зручності використання',
          ],
        },
        {
          id: 4,
          title: 'QA-інженер (R)',
          skills: ['Досвід функціонального та автоматизованого тестування'],
        },
        {
          id: 5,
          title: 'DevOps-інженер (R)',
          skills: ['Знання AWS, Docker, CI/CD'],
        },
        {
          id: 6,
          title: 'Спеціаліст з маркетингу (R)',
          skills: [
            'Досвід просування IT-продуктів',
            'Навички контент-маркетингу та SMM',
          ],
        },
      ],
      advantages: [
        'Віртуальні бонуси: Нарахування еБонусів з перспективою реальної компенсації після запуску проекту.',
        'Досвід роботи у стартапі: Занурення в динамічне середовище з можливістю впливати на продукт.',
        'Відсутність бюрократії: Пряме взаємодія з фаундером, швидкі рішення та прозорі процеси.',
      ],
      additional: [
        'Гнучка методологія: Використання Agile/Scrum для організації роботи.',
        'Сучасні інструменти: Робота з актуальними технологіями та стеками.',
        'Комунікації: Щоденні стендапи, спілкування через Slack та Zoom.',
      ],
      faq: [
        {
          id: 1,
          qs: 'Чи буде фінансова компенсація за роботу над проектом?',
          an: 'На етапі розробки MVP компенсація здійснюється через систему еБонусів. Після успішного запуску проекту планується офіційне працевлаштування та фінансова винагорода.',
        },
        {
          id: 2,
          qs: 'Скільки часу потрібно буде приділяти проекту?',
          an: 'Графік роботи гнучкий. Очікується, що учасники зможуть присвячувати проекту від 15 годин на тиждень.',
        },
        {
          id: 3,
          qs: 'Які перспективи зростання всередині проекту?',
          an: 'Учасники, які проявили себе на етапі розробки MVP, зможуть зайняти ключові позиції в компанії після її запуску.',
        },
      ],
      cta: "Не втрачай можливість стати частиною команди, яка змінює підхід до управління проектами! Зв'яжись з нами та почни свій шлях до нових досягнень разом з еБонусами.",
      social: [
        {
          title: 'GitHub репозиторій',
          url: 'github.com/ebonus',
        },
        {
          title: 'Facebook',
          url: 'facebook.com/ebonus',
        },
        {
          title: 'Instagram',
          url: '@ebonus',
        },
      ],
    },
  },
  {
    id: 1,
    title: 'AgroSense',
    name: 'Олександр Ткаченко',
    desc: 'Платформа для моніторингу стану ґрунту та врожайності фермерів',
    bonus: 5700,
    status: 'Активний',
    avatars: [pr11, pr12, pr13, pr14, pr15],
    pic: pr1,
  },
  {
    id: 2,
    title: 'EcoPackUA',
    name: 'Ірина Грищенко',
    desc: 'Екологічна альтернатива пластиковим упаковкам для бізнесу',
    bonus: 5200,
    status: 'Початковий',
    avatars: [pr21, pr22, pr23],
    pic: pr2,
  },
  {
    id: 3,
    title: 'SmartCityHub',
    name: 'Дмитро Ковальчук',
    desc: 'Рішення для оптимізації управління міськими ресурсами',
    bonus: 4300,
    status: 'Реєстрація',
    avatars: [pr31, pr32, pr33, pr34],
    pic: pr3,
  },
  {
    id: 4,
    title: 'HealthGuard',
    name: 'Дмитро Шевченко',
    desc: "Мобільний додаток для моніторингу здоров'я та попередження захворювань",
    bonus: 6200,
    status: 'Активний',
    avatars: [pr11, pr12, pr13],
    pic: pr4,
  },
  {
    id: 5,
    title: 'EduSpace',
    name: 'Ірина Ковальчук',
    desc: 'Онлайн-платформа для дистанційного навчання з інтерактивними можливостями',
    bonus: 6100,
    status: 'Початковий',
    avatars: [pr21, pr22, pr23],
    pic: pr5,
  },
  {
    id: 6,
    title: 'FarmFresh',
    name: 'Олег Петренко',
    desc: 'Сервіс доставки свіжих фермерських продуктів безпосередньо до споживача',
    bonus: 5300,
    status: 'Активний',
    avatars: [pr31, pr32, pr33, pr34],
    pic: pr6,
  },
  {
    id: 7,
    title: 'SafeDrive',
    name: 'Наталія Гончарова',
    desc: 'Система для підвищення безпеки на дорозі через аналіз поведінки водія',
    bonus: 5900,
    status: 'Початковий',
    avatars: [pr11, pr12, pr13, pr14, pr15],
    pic: pr7,
  },
  {
    id: 8,
    title: 'ArtConnect',
    name: 'Михайло Іванченко',
    desc: 'Платформа для поєднання митців з потенційними покупцями та галереями',
    bonus: 6000,
    status: 'Реєстрація',
    avatars: [pr21, pr22, pr23],
    pic: pr8,
  },
];

export default projects;
